/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, FullmapCover, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ekso57 --style2 --left --full --parallax" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26565/778c6a1fba0a44088befee928fd7641e_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --center el--1 flex--center" anim={"7"} style={{"maxWidth":1360,"paddingBottom":0}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pb--60 pt--60" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":"","marginTop":0,"paddingTop":0,"paddingLeft":24,"marginBottom":0}} content={"<span style=\"color: white;\">Kontakt</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"99poxpvd2w9"} style={{"paddingTop":74}} layout={"l5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Title className="title-box fs--30" style={{"marginBottom":35}} content={"GeneralPack s.r.o."}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":440}} content={"Tel.: <span style=\"color: var(--color-custom-2);\">+420 <span style=\"font-weight: bold;\">604 741 713</span></span><br>Email: <span style=\"font-weight: bold; color: var(--color-custom-1--95);\"><a href=\"mailto:info@generalpack.cz?subject=Zpr%C3%A1va+z+webu\" style=\"\">info@generalpack.cz</a></span><br>"}>
              </Text>

              <Text className="text-box lh--16" style={{"maxWidth":440,"marginTop":19}} content={"Pražákova 1008/69<br>639 00&nbsp;Brno<br>Česká republika<br>IČO: 10775731<br>DIČ: CZ10775731<br>"}>
              </Text>

              <Text className="text-box fs--13" style={{"maxWidth":666,"marginTop":18}} content={"Spisová značka oddílu C,\nvložka 122788 ze dne 21.04.2021\nu Krajského soudu v Brně.&nbsp;<br><br>"}>
              </Text>

              <Title className="title-box fs--48" style={{"marginBottom":57}} content={"Kontaktní formulář"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 flex--stretch" columns={"2"}>
            
            <ColumnWrapper >
              
              <FullmapCover place={"Pražákova 10008/69 Brno"} style={{"overflow":"hidden","borderRadius":"6px"}}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3 --style1" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footer"} style={{"marginTop":0,"paddingTop":43,"paddingBottom":35,"backgroundColor":"#f3f3f3"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--22" content={"GeneralPack s.r.o.<br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334,"marginTop":32}} content={"Pražáková 1008/69<br>639 00 Brno<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--22" content={"Rychlý kontakt"}>
              </Title>

              <Text className="text-box text-box--right" style={{"marginTop":36}} content={"Email: info@generalpack.cz<br>Tel.: +420 604 741 713<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}